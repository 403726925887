import gql from 'graphql-tag'
import { formatNumberComma } from 'utils'
import { useCoverLetterTotalCountQuery } from 'generated/graphql'

gql`
  query CoverLetterTotalCount {
    coverLetterTotalCount
  }
`

const useCoverLetterCount = () => {
  const { data, loading } = useCoverLetterTotalCountQuery({
    fetchPolicy: 'cache-first',
  })
  const count = formatNumberComma(data?.coverLetterTotalCount || 12469)

  return { loading, count }
}

export default useCoverLetterCount
